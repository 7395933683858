const { gtmService } = require('./src/services/gtmService');
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

exports.wrapPageElement = ({ props }) => {
  const { pageTemplate } = props.pageContext || {};
  gtmService.emitPageView(pageTemplate);
};
