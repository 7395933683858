import ELEMENTS from './constants';

export const isBrowser = () => typeof window !== 'undefined';

export const isTouchDevice = () => isBrowser() && 'ontouchstart' in window;

export const getLocationPath = () => (isBrowser() ? window.location.pathname : null);

export const getLocationQS = () => (isBrowser() ? window.location.search : null);

export const getLocationQueryStringParam = (
  param: string,
  strategy: 'string' | 'array' = 'array'
) => {
  if (!isBrowser()) {
    return strategy === 'string' ? '' : [];
  }
  const qs = new URLSearchParams(window.location.search);

  const stringValue = decodeURIComponent(qs.get(param) || '');
  if (strategy === 'string') {
    return stringValue;
  }

  try {
    return JSON.parse(`[${stringValue}]`);
  } catch (e) {
    console.error(e);

    return [];
  }
};

export const generateLocationQueryString = (param: string, value: string) => {
  if (!isBrowser()) {
    return '';
  }
  const qs = new URLSearchParams(window.location.search);
  qs.set(param, value);

  return `${getLocationPath()}?${qs.toString()}`;
};

export const scrollToTarget = (id: string): void => {
  if (!isBrowser()) {
    return;
  }
  const element: HTMLElement | null = document.getElementById(id);
  const header: HTMLElement | null = document.getElementById(ELEMENTS.HEADER_ID);
  const headerHeight: number = header ? header.clientHeight : 0;

  if (element) {
    const bodyRect: number = document.body.getBoundingClientRect().top;
    const elementRect: number = element.getBoundingClientRect().top;

    const elementPosition: number = elementRect - bodyRect;
    const offsetPosition: number = elementPosition - headerHeight;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }
};
